<template>
  <div
      class="requestCard"
      v-on:click="handleShowDetails"
  >
    <div class="requestCard__title">
      <span class="text__psb">
        {{ item.patientName }}
      </span>
      <span class="text__mrg">
        {{ item.specialty }}
      </span>
      <div v-if="item.to">
        <span class="text__sr">
          {{ `${$i18n.t('lb_to')}:` }}
        </span>
        <span class="text__sb">
          {{ item.to }}
        </span>
      </div>
      <div v-if="item.from">
        <span class="text__sr">
          {{ `${$i18n.t('lb_from')}:` }}
        </span>
        <span class="text__sb">
          {{ item.from }}
        </span>
      </div>
    </div>
    <div>
      <span class="text__srg">
        {{ item.date }}
      </span>
      <Badge
        v-if="item.status"
        :variant="item.status"
      >
        {{ getVariantByStatus(item.status) }}
      </Badge>
    </div>
  </div>
</template>
<script>
import { getVariantByStatus } from '@/helpers/ComponentHelper';

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  mounted() {},
  methods: {
    handleShowDetails() {
      this.$emit('handleShowDetail', this.item.reqId, this.item.invId);
    },
    getVariantByStatus(status) {
      return this.$i18n.t(getVariantByStatus(status));
    },
  },
  computed: {
  },
  components: {
    Badge: () => import('@/components/atoms/Badge/Badge.vue'),
  },
};
</script>
<style lang="sass" scoped>
    @import './RequestCard.sass'

</style>
